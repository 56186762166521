import {
  EuiHeader,
  EuiHeaderLink,
  EuiHeaderLinks,
  EuiHeaderLogo,
  EuiIcon,
  EuiHeaderSectionItemButton,
  useEuiTheme,
} from '@elastic/eui';

import logo from '../assets/continuation.png';

interface HeaderProps {
  isDark: boolean;
  setIsDark: Function;
}

export const Header:React.FC<HeaderProps> = ({ isDark, setIsDark }) => {
  const { euiTheme } = useEuiTheme();
  console.log(euiTheme.colors.lightestShade);
  return (
    <>
      <EuiHeader
        theme={isDark ? 'default' : 'dark'}
        sections={[
          {
            items: [
              <EuiHeaderLogo iconType={logo}>Davey Holler</EuiHeaderLogo>,
              <EuiHeaderLinks aria-label="App navigation dark theme example">
                <EuiHeaderLink href="https://github.com/daveyholler" target="_blank">GitHub</EuiHeaderLink>
                <EuiHeaderLink href="https://www.figma.com/proto/IMU0H6MCbwwWA3yzRXc7yx/Holler?page-id=51%3A367&node-id=53%3A432&viewport=1267%2C739%2C0.89&scaling=min-zoom&starting-point-node-id=53%3A432" target="_blank">Portfolio</EuiHeaderLink>
                <EuiHeaderLink href="https://gist.github.com/c4020ba1a6528d1e8d951aa43ba4dcc1" target="_blank">Résumé</EuiHeaderLink>
              </EuiHeaderLinks>,
            ],
            borders: 'right',
          },
          {
            items: [
              <EuiHeaderSectionItemButton onClick={() => setIsDark(!isDark)}>
                <EuiIcon type={isDark ? 'sun' : 'moon'} />
              </EuiHeaderSectionItemButton>,
            ],
            borders: 'none',
          },
        ]}
      />
    </>
  )
};
