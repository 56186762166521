import avetta from '../assets/avetta.png';
import dealersocket from '../assets/dealersocket.png';
import elastic from '../assets/elastic.png';
import motortrend from '../assets/motortrend.png';


export interface IJob {
  title: string;
  company: string;
  companyBio: string;
  startDate: string;
  endDate: string;
  highlights: string[];
  website: string;
  image: string;
}

export const JOBS:IJob[] = [
  {
    title: 'Principal Designer',
    company: 'Elastic',
    companyBio: 'From startups to the global 2000, Elastic powers search solutions for thousands of companies worldwide to find documents, monitor infrastructure, protect against security threats, and more.',
    startDate: 'October 2018',
    endDate: 'Current',
    website: 'https://elastic.co',
    image: elastic,
    highlights: [
      'Led design efforts on Codesearch and Enterprise Search',
      'Led design on the large-scale effort of migrating App Search into Kibana while educating our engineers on the Elastic design language',
      'Provided product management, front-end development and code review support over the course of dozens of projects large and small',
      'Served as a mentor for new hires and helped foster the Elastic culture',
      'Developed a series of videos to help designers level up their coding skills',
    ],
  },
  {
    title: 'Design Lead',
    company: 'Avetta',
    companyBio: 'Avetta provides a SaaS product to help large organizations, like Amazon, GE, and Quantas Airlines, manage the environmental, health, and safety risk within their supply chains',
    startDate: 'December 2016',
    endDate: 'October 2018',
    website: 'https://avetta.com',
    image: avetta,
    highlights: [
      'Hired to build an internal team that replace a contracted UX firm. Took over all operations, saving the company more than $2 million in contract expenses',
      'Designed and developed a comprehensive design system in React and Sketch that was adopted by internal teams and vendor-partners. Viewable at https://avetta.design',
      'Pioneered user research practices within the organization',
      'Hosted talks for hundreds of customers at the annual User Summit',
      'Developed and implemented a full-fledged design system built in React.js',
    ],
  },
  {
    title: 'Sr. Manager, UX Strategy',
    company: 'Dealersocket',
    companyBio: 'Dealersocket provides end-to-end SaaS solutions to franchise and independent automotive dealerships across the globe',
    startDate: 'March 2015',
    endDate: 'December 2016',
    website: 'https://dealersocket.com',
    image: dealersocket,
    highlights: [
      'Built a user research and rapid prototyping department from the ground up',
      'Led and conducted on-site usability sessions and interviews with customers at dozens of automotive dealerships',
      'Built and implemented a design system that aided in onboarding new design teams during a period of rapid acquisitions',
      'Developed training manuals, best practices, and health-checks that helped steer our design, product, and engineering teams',
      'Provided front-end development consulting to our many engineering teams who were previously very back-end focused',
      'Assisted with the evaluation and implementation of Elasticsearch inside the SaaS products',
    ],
  },
  {
    title: 'Designer → Product Manager',
    company: 'MotorTrend Group',
    companyBio: 'Automotive publishing company with dozens of brands and properties. Home to world class outlets like Motortrend and Automobile Magazine',
    startDate: 'December 2012',
    endDate: 'March 2015',
    website: 'https://www.motortrendgroup.com',
    image: motortrend,
    highlights: [
      'Led the design efforts on the conversion of dozens of sites from legacy publishing systems to a modern tech stack',
      'Increased annual revenue on the HotRod website by more than 50% over the course of six months',
      'Pioneered new navigational patterns that were adopted by more than a dozen digital properties across the company',
      'Worked directly with our Editors, Publishers, and Sales teams to create cohesive experiences across print and digital mediums',
    ]
  }
]
