import { css, cx } from '@emotion/css';
import {
  EuiBadge,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle,
  useEuiTheme,
} from '@elastic/eui';

import { IJob } from '../data/data';

interface JobProps {
  job: IJob;
  key: number;
}

export const JobCard:React.FC<JobProps> = ({
  job,
}) => {
  const { euiTheme } = useEuiTheme();
  return (
    <EuiFlexItem>
      <EuiPanel
        paddingSize="l"
        className={css`
          transition: all .15s ease-in-out;
          &:hover {
            transform: rotate(-1.08deg);
            box-shadow: 0 2.7px 9px rgba(0, 0, 0, .13), 0 9.4px 24px rgba(0, 0, 0, .09), 0 21.8px 43px rgba(0, 0, 0, .08);
          }
        `}
      >
        {/* HEADER */}
        <EuiFlexGroup alignItems="center" gutterSize="m">
          <EuiFlexItem grow={false}>
            <EuiIcon type={job.image} size="xxl"/>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFlexGroup direction="column" gutterSize="xs">
              <EuiFlexItem>
                <EuiTitle size="xs"><h2>{job.title}</h2></EuiTitle>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFlexGroup>
                  <EuiFlexItem grow><a href={job.website} target="_blank">@{job.company}</a></EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiFlexGroup gutterSize="s" alignItems="center">
                      <EuiFlexItem>
                        <EuiBadge color="default">{job.startDate}</EuiBadge>
                      </EuiFlexItem>
                      <EuiFlexItem grow={false}><EuiText size="s"><h6>to</h6></EuiText></EuiFlexItem>
                      <EuiFlexItem>
                        <EuiBadge color="default">{job.endDate}</EuiBadge>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer />
        {/* BODY */}
        <EuiFlexGroup direction="column">
          <EuiFlexItem>
            <EuiText size="xs">
              <p className={css`
                font-style: italic;
                padding: .5rem 0 .5rem 1rem;
                border-left: .25rem solid ${euiTheme.colors.accent}
              `}>{job.companyBio}</p>
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiPanel color="subdued">
              <EuiTitle size="xxs">
                <h3>
                  <EuiIcon type="cheer" />&nbsp;
                  Highlights
                </h3>
              </EuiTitle>
              <EuiText size="xs">
                <ul className={css`margin-top: .75rem;`}>
                  {job.highlights.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </EuiText>
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>
    </EuiFlexItem>
  )
}
