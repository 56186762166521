import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import '@elastic/eui/dist/eui_theme_light.css';
import './index.css';
import { App } from './App';
import reportWebVitals from './reportWebVitals';

import { EuiProvider } from '@elastic/eui';

const Root = () => {
  const [isDark, setIsDark] = useState(false);

  return (
    <EuiProvider colorMode={isDark ? 'dark' : 'light'}>
      <App isDark={isDark} setIsDark={setIsDark} />
    </EuiProvider>
  )
}
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
