import {
  EuiFlexGroup,
} from '@elastic/eui';

import { JobCard } from './jobCard';
import { IJob } from '../data/data';

interface IJobList {
  jobs: IJob[];
}
export const JobList:React.FC<IJobList> = ({ jobs }) => {
  return (
    <EuiFlexGroup direction="column">
      {jobs.map((job, index) => (
        <JobCard job={job} key={index} />
      ))}
    </EuiFlexGroup>
  )
}
