import { css } from '@emotion/css';
import {
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle,
  EuiToolTip,
  useEuiTheme,
} from '@elastic/eui';

import { JOBS } from './data/data';
import { JobList } from './components/jobList';
import { Header } from './components/header';
import github from './assets/github.svg';
import slack from './assets/slack.svg';
import envelope from './assets/envelope.svg';
import globe from './assets/globe.svg';

interface AppProps {
  isDark: boolean;
  setIsDark: Function;
}

export const App:React.FC<AppProps> = ({ isDark, setIsDark }) => {
  const { euiTheme } = useEuiTheme();

  const blockquoteStyle = css`
    text-align: left;
    border-left: .25rem solid ${euiTheme.colors.lightShade};
    & > p {
      text-align: left;
    }
    cite {
      width: 100%;
      text-align: left;
      display: inline-block;
    }
    &::before, &::after {
      display: none;
    }
  `;
  return (
    <>
      <Header isDark={isDark} setIsDark={setIsDark} />
      <EuiFlexGroup style={{ width: '100vw', paddingBottom: '4rem' }}>
        <EuiFlexItem></EuiFlexItem>
        <EuiFlexItem grow={false}>
          <div className={css`
            max-width: 40rem;
          `}>
            <EuiPanel
              paddingSize="l"
              color="subdued"
              grow={false}
            >
              <EuiTitle size="s">
                <h2>A little about me...</h2>
              </EuiTitle>
              <EuiSpacer />
              <EuiText>
                <p>I'm a husband, father, musician, and enthusiast. I've been designing for the last 18 years. I started my career designing physical spaces and have spent the last 12 years applying my skills and knowledge to the digital realm. I have a passion for understanding technical constraints and pushing the envelope wherever I can. I have a proven track record for building highly functional trust-based relationships with folks across the organization.</p>
                <p>Aside from providing design support for Code, Enterprise Search, and EUI, I've spent my time at Elastic helping bridge the technical gap between traditional design practices and engineering deliverables. I've leveraged my technical skills to help interested designers level up their own skills as well as reducing the time it takes to go from concept to completion.</p>
                <p>I love what I do and I'm excited about the opportunity to help drive our design organization forward through shared UX — unifying disparate patterns and empowering engineering teams to implement proven interaction models with as little effort as using EUI itself.</p>
              </EuiText>
              <EuiSpacer />
              <EuiFlexGroup>
                <EuiFlexItem grow={false}>
                  <EuiToolTip content="View GitHub profile">
                    <EuiButtonIcon iconType={github} href="https://github.com/daveyholler" />
                  </EuiToolTip>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiToolTip content="View Slack profile">
                    <EuiButtonIcon iconType={slack} href="https://elastic.slack.com/team/UDE5LV1D2" />
                  </EuiToolTip>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiToolTip content="Visit website">
                    <EuiButtonIcon iconType={globe} href="https://daveyholler.com" />
                  </EuiToolTip>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiToolTip content="Send an email">
                    <EuiButtonIcon iconType={envelope} href="mailto:davey.holler@elastic.co" />
                  </ EuiToolTip>
                </EuiFlexItem>
              </EuiFlexGroup>
              <EuiSpacer />
              <EuiText size="s">
                <EuiTitle size="xs">
                  <h2>Endorsements from other Elasticians</h2>
                </EuiTitle>
                <EuiSpacer />
                <blockquote className={blockquoteStyle}>
                  <p>Davey is one of the best design-engineers I’ve known, a true comb-shaped designer, taking on roles where there are gaps. His ambition to continue to learn and to teach is valuable to every team he is on and his positivity infectious.</p>
                  <cite>Caroline Horn, Principal Designer II, Shared UX</cite>
                </blockquote>
                <blockquote className={blockquoteStyle}>
                  <p>Davey is one of the best designers I've had the pleasure of working with - he has a razor sharp eye for visuals and feel for great user experience, sets the bar scarily high for technical skills in designers, and above all is a fantastic communicator and compromiser which makes him such an awesome team member. I would be over the moon to be working closely with Davey again.</p>
                  <cite>Costance Chen, EUI</cite>
                </blockquote>
                <blockquote className={blockquoteStyle}>
                  <p>The only reason I would hesitate to recommend Davey is because I want him to keep working with our team.<br/><br/>We do such high quality work in large part because of Davey's hand from strategy to ship. I deeply value his ability to work with product and engineering to dig into the underlying why, come up with elegant solutions, and co-ordinate implementation to ensure everything we do is done well. Any team would be lucky to have him.</p>
                  <cite>Nick Chow, Product Manager, Enterprise Search</cite>
                </blockquote>
                <blockquote className={blockquoteStyle}>
                  <p>Davey is a consummate professional with an impeccable design aesthetic. As a product manager, it's key to build amazing product experiences to be able to work with partners like him. User centric, with strong product thinking, his holistic approach to design and collaborative style will be a competitive advantage to any team looking to take their product to the next level.</p>
                  <cite>Serena Chou, Product Manager, Enterprise Search</cite>
                </blockquote>
              </EuiText>
            </EuiPanel>
          </div>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <div style={{ maxWidth: '40rem', margin: 'auto', paddingTop: '2rem' }}>
            <JobList jobs={JOBS} />
          </div>
        </EuiFlexItem>
        <EuiFlexItem></EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
}
